<template>
	<div class="charts position-relative">

			<div class="px-10 px-sm-20 pt-20 pb-10 bg-white border-bottom border-brd-primary position-relative">
				<h1 class="page-title mb-3">Календарь</h1>

                <Switcher inputId="only_active"
						  inputName="only_active"
						  inputLabel="Только активные"
						  inputLabelClass="form-check-label text-font-secondary"
						  inputClass="p-0"
						  :inputValue="(!this.isEmpty(this.filters['only_active'])) ? 1 : 0"
						  inputWrapClass="mb-3"
						  @setValue="(value) => this.setupFilter('only_active', value)" />
			</div>

            <div class="page-content">
				<CalendarComponent ref="calendar"
								   :inputFilters="this.filters"
								   @openEventModal="(data) => this.openEventModal(data)"/>
            </div>

            <div class="page-footer p-20">
                <div class="border border-brd-primary rounded-3 bg-white p-20">
                    <Notes
                        :notes="noteList"
                    />
                </div>
            </div>
	</div>

	<HistoryTasksModal ref="historyTasksModal"
					   tempBlockTitle="История и задачи"
					   :fromSection="this.modal.section"
					   :injectData="{
						   object_id: this.modal.object_id,
						   client_id: this.modal.client_id,
						   requisition_id: this.modal.requisition_id,
					   }" />

	<EmployeeInfoModal ref="employeeInfoModal"
					   :fromSection="this.modal.section"
					   :injectData="{
						   employee_id: this.modal.employee_id,
					   }" />
</template>

<script>
    import Switcher from '@/components/inputs/Switcher'
    import Notes from '@/components/common/Notes'
    import CommonService from "../services/CommonService";
	import CalendarComponent from "@/components/common/CalendarComponent";
	import api from "@/api";
	import moment from "moment";
	import HistoryTasksModal from "../components/modals/HistoryTasksModal";
	import * as bootstrap from "bootstrap";
	import EmployeeInfoModal from "../components/modals/EmployeeInfoModal";

    export default {

        name: "Calendar",

		components: {
			EmployeeInfoModal,
			HistoryTasksModal,
            Notes,
            Switcher,
            CalendarComponent
		},

        data() {
            return {
                title: "Календарь событий",
				filters: {},
				list: [],
				modal: {
					section: null,
					employee_id: null,
					object_id: null,
					client_id: null,
					requisition_id: null,
				},
                noteList: [
                    'В календаре представлены все задачи (события), которые были запланированы на определенную дату и время. При клике на задачу откроется окно с объектом, по которому она была создана, и список всех задач и комментариев по нему.',
                    'Слева в меню Календарь цифрой показывается количество событий, которые должны были наступить или уже наступили (запланированная дата меньше или равна текущей).',
                    'При клике на такое событие оно помечается как просмотренное и будет отображаться серым шрифтом. Соответственно, просмотренные события не учитываются при подсчете количества событий в меню Календарь.'
                ],
            }
        },

		methods: {
			moment(config) {
				if (typeof config != "undefined")
					return moment(config);
				else
					return moment();
			},
			sortArray(data) {
				return CommonService.sortArray(data);
			},
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
			inArray(needle, haystack) {
				return CommonService.inArray(needle, haystack);
			},
			openEventModal(data) {

				this.modal.section = null;
				this.modal.employee_id = null;
				this.modal.object_id = null;
				this.modal.client_id = null;
				this.modal.requisition_id = null;

				/*this.modal.object_id = data.object_id;
				this.modal.employee_id = data.employee_id;
				this.modal.event_id = data.event_id;
				this.modal.is_viewed = data.is_viewed;*/

				let modal_id = null;
				if (data.section == 'objects' && this.inArray(data.event_type, ['call', 'meet', 'show', 'deal', 'moderation', 'sub_moderation']) && !this.isEmpty(data.object_id)) {
					modal_id = 'historyTasksModal';
					this.modal.section = 'objects';
					this.modal.object_id = data.object_id;
				} else if (data.section == 'clients' && this.inArray(data.event_type, ['call', 'meet', 'show', 'deal', 'even', 'birthday']) && !this.isEmpty(data.client_id)) {
					modal_id = 'historyTasksModal';
					this.modal.section = 'clients';
					this.modal.client_id = data.client_id;
				} else if (data.section == 'requisitions' && this.inArray(data.event_type, ['call', 'meet', 'show', 'deal', 'even']) && !this.isEmpty(data.requisition_id)) {
					modal_id = 'historyTasksModal';
					this.modal.section = 'requisitions';
					this.modal.requisition_id = data.requisition_id;
				} else if (data.section == 'partners' && data.event_type == 'birthday' && !this.isEmpty(data.employee_id)) {
					modal_id = 'employeeInfoModal';
					this.modal.section = 'partners';
					this.modal.employee_id = data.employee_id;
				} else if (data.section == 'users' && data.event_type == 'birthday' && !this.isEmpty(data.employee_id)) {
					modal_id = 'employeeInfoModal';
					this.modal.section = 'users';
					this.modal.employee_id = data.employee_id;
				}

				console.info({modal_id, modal: this.modal, data});

				if (modal_id && !this.isEmpty(this.modal.section)) {
					let modal = CommonService.getModal(modal_id);
					modal.show();
				}
			},
			filterChange(filters) {

				CommonService.log('log', 'filterChange()', this.filters);
				this.$refs.calendar.$forceUpdate();

				/*this.filters = CommonService.removeEmpty(filters);
				CommonService.log('log', 'setupFilter()', this.filters);*/
			},
			applyFilter(filters) {
				CommonService.log('log', 'applyFilter()', this.filters);
				/*this.loadObjects(false, false, this.section, this.filters); */
			},
			setupFilter(filter_name, filter_value) {

				let filter_parent = null;
				let filter_parts = filter_name.split('.', 2);
				if (filter_parts.length == 2) {
					filter_parent = filter_parts[0];
					filter_name = filter_parts[1];
				}

				if (filter_parent && filter_name && filter_value) {

					if (!this.isEmpty(this.filters[filter_parent])) {
						this.filters[filter_parent] = {
							...{
								[filter_name]: filter_value
							},
							...this.filters[filter_parent]
						};
					} else {
						this.filters[filter_parent] = {
							[filter_name]: filter_value
						};
					}
				} else if (filter_name && filter_value) {
					if (filter_name == 'status') {
						if (this.filters['status'] == filter_value)
							this.filters['status'] = null;
						else
							this.filters[filter_name] = filter_value;
					} else {
						this.filters[filter_name] = filter_value;
					}
				} else {
					if (filter_parent)
						delete this.filters[filter_parent][filter_name];
					else
						delete this.filters[filter_name];
				}

				if (this.isEmpty(this.filters['search_query']))
					this.filters['search_query'] = null;

				if (filter_name == 'period' && this.filters['period'] != 9 && filter_value !== 9) {
					this.filters['period_start'] = null;
					this.filters['period_end'] = null;
				}

				if (process.env.NODE_ENV == "development") {
					if (!this.isEmpty(filter_parent)) {
						console.debug('setupFilter()', {
							name: filter_parent + '.' + filter_name,
							value: filter_value,
							filters: this.filters
						});
					} else {
						console.debug('setupFilter()', {
							name: filter_name,
							value: filter_value,
							filters: this.filters
						});
					}
				}


				CommonService.log('log', 'setupFilter()', this.filters);
				this.$emit('setupFilter', this.filters);
			},
			resetFilter() {

				this.$refs.searchInput.value = null;
				if (Object.keys(this.filters).length > 0) {

					this.filters = {};
					CommonService.log('log', 'resetFilter()', this.filters);

				}

			},
		},
		
        mounted() {
			CommonService.setPageTitle(this.title);
			this.emitter.on('closeFilter', () => this.resetFilter());
        }
    }
</script>